<template>
  <div class="brands">
    <c-carousel
      :autoplay="true"
      :centerMode="true"
      :adaptiveHeight="false"
      :centerPadding="'0px'"
      :focusOnSelect="true"
      :slidesToShow="4"
      :slidesToScroll="4"
      :variableWidth="true"
      :responsive="[
        {
          breakpoint: $root.scss.grid['breakpoint-xl-down'].value,
          settings: {
            centerPadding: '0px',
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: $root.scss.grid['breakpoint-md-down'].value,
          settings: {
            centerPadding: '0px',
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: $root.scss.grid['breakpoint-sm-down'].value,
          settings: {
            centerPadding: '0px',
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]"
    >
      <slot/>
    </c-carousel>

    <div class="brands-footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Brands'
}
</script>

<style lang="scss">
$brands-margin: 0 0 $component-gap !default;
$brands-carousel-list-padding-y: $component-padding-y !default;
$brands-carousel-list-padding-x: 0 !default;
$brands-carousel-list-bg: $white !default;
$brands-carousel-list-box-shadow-size: 4vw !default;

$brands-logo-max-height: 80px !default;
$brands-logo-opacity: 0.5 !default;
$brands-logo-filter: grayscale(1) !default;
$brands-logo-transition: all ease-in-out 300ms !default;
$brands-logo-hover-opacity: 1 !default;
$brands-logo-hover-filter: grayscale(0) !default;

$brands-footer-gap: $spacer !default;

.brands {
  margin: $brands-margin;

  div.carousel {
    margin: 0;

    .slick-list {
      @include make-leaf();
      background-color: $brands-carousel-list-bg;

      &:before,
      &:after {
        display: block;
        content: '';
        position: absolute;
        top: $brands-carousel-list-box-shadow-size * -1.2;
        bottom: $brands-carousel-list-box-shadow-size * -1.2;
        width: $brands-carousel-list-box-shadow-size * 2.4;
        z-index: 2;
      }

      &:before {
        left: 0;
        box-shadow: inset ($brands-carousel-list-box-shadow-size * 1.2) 0 ($brands-carousel-list-box-shadow-size) 0 $brands-carousel-list-bg;
      }

      &:after {
        right: 0;
        box-shadow: inset ($brands-carousel-list-box-shadow-size * -1.2) 0 ($brands-carousel-list-box-shadow-size) 0 $brands-carousel-list-bg;
      }

      .slick-track {
        padding: $brands-carousel-list-padding-y $brands-carousel-list-padding-x;
        z-index: 1;
      }

      .slick-slide {
        opacity: 1;

        img {
          width: auto;
          height: $brands-logo-max-height;
          opacity: $brands-logo-opacity;
          filter: $brands-logo-filter;
          transition: $brands-logo-transition;
        }

        &:hover {
          img {
            opacity: $brands-logo-hover-opacity;
            filter: $brands-logo-hover-filter;
          }
        }
      }
    }
  }

  .brands-footer {
    margin-top: $brands-footer-gap;
    text-align: right;
  }
}
</style>
