var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brands"},[_c('c-carousel',{attrs:{"autoplay":true,"centerMode":true,"adaptiveHeight":false,"centerPadding":'0px',"focusOnSelect":true,"slidesToShow":4,"slidesToScroll":4,"variableWidth":true,"responsive":[
      {
        breakpoint: _vm.$root.scss.grid['breakpoint-xl-down'].value,
        settings: {
          centerPadding: '0px',
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: _vm.$root.scss.grid['breakpoint-md-down'].value,
        settings: {
          centerPadding: '0px',
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: _vm.$root.scss.grid['breakpoint-sm-down'].value,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]}},[_vm._t("default")],2),_c('div',{staticClass:"brands-footer"},[_vm._t("footer")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }